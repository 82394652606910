import Accordion from '../components/accordion.js';

const selectors = {
  menu: '[data-mobile-menu]',
  menuOpen: '[data-open-mobile-menu]',
  menuClose: '[data-close-mobile-menu]',
  menuMainItem: '[data-mobile-menu-main-item]',
  childActive: '[data-mobile-menu-child-active]',
  closeChildList: '[data-mobile-menu-close-child-list]',
  bgOverlay: '[data-bg-overlay]',
  body: 'body',
  drawerButton: '[data-accordion-drawer-button]',
  navDrawerContent: '[data-accordion-drawer-content]',
  noticeBarMenuMobile: '[data-notice-bar-menu-mobile]'
};

const classes = {
  hideMenu: '-translate-x-full',
  insetO: 'inset-0',
  bgBlack: 'bg-black/50',
  navAccordion: '.nav-accordion',
  disableScroll: 'overflow-hidden',
  disableTouchScroll: 'touch-none',
  touchScroll: 'touch-auto',
  PointerEventNone: ['pointer-events-none', 'xl:pointer-events-auto']
};

class NavigationMobile {
  constructor() {
    this.accordionInitilised = false;

    this.body = document.querySelector(selectors.body);
    this.menu = document.querySelector(selectors.menu) ?? false;
    this.mobileOpenBtn = document.querySelector(selectors.menuOpen) ?? false;
    this.mobileCloseBtn = document.querySelector(selectors.menuClose) ?? false;
    this.menuMainItems = document.querySelectorAll(selectors.menuMainItem);
    this.closeChildList = document.querySelectorAll(selectors.closeChildList);
    this.bgOverlay = document.querySelector(selectors.bgOverlay) ?? false;
    this.drawerButton = document.querySelectorAll(selectors.drawerButton);
    this.drawerContent = document.querySelectorAll(selectors.navDrawerContent);
    this.navAccordion = document.querySelector(classes.navAccordion) ?? false;
    this.search = document.querySelectorAll(selectors.search);
    this.noticeBarMenuMobile = document.querySelector(selectors.noticeBarMenuMobile) ?? false;

    this.breakpoint = window.matchMedia('(max-width:1279px)');

    this.initEventListeners();
    this.handleAccordion();

    window.addEventListener('resize', () => {
      this.handleAccordion();
    });

    this.openChildList();
  }

  initEventListeners() {
    if (!this.mobileOpenBtn || !this.mobileCloseBtn) return;

    this.mobileOpenBtn.addEventListener('click', () => {
      this.showMenu();
    });

    [this.mobileCloseBtn, this.bgOverlay].forEach(elem => {
      elem.addEventListener('click', () => {
        this.hideMenu();
        this.closeAllActive();
      });
    });
  }

  showMenu() {
    this.menu.classList.remove(classes.hideMenu);
    this.showBgOverlay();
    this.disablePageScroll();
  }

  hideMenu() {
    this.menu.classList.add(classes.hideMenu);
    this.hideBgOverlay();
    this.enablePageScroll();
  }

  showBgOverlay() {
    this.bgOverlay.classList.add(classes.insetO);
    this.bgOverlay.classList.add(classes.bgBlack);
  }

  hideBgOverlay() {
    this.bgOverlay.classList.remove(classes.bgBlack);
    setTimeout(() => {
      this.bgOverlay.classList.remove(classes.insetO);
    }, 700);
  }

  disablePageScroll() {
    this.body.classList.add(
      classes.disableScroll,
      classes.disableTouchScroll
    );
    this.menu.classList.add(classes.touchScroll);
  }

  enablePageScroll() {
    this.body.classList.remove(
      classes.disableScroll,
      classes.disableTouchScroll
    );
    this.menu.classList.remove(classes.touchScroll);
  }

  openChildList () {
    this.menuMainItems.forEach( item => {
      item.addEventListener('click', () => {
        const childList = item.parentNode.querySelector(selectors.childActive);
        childList.dataset.mobileMenuChildActive = 'true';
        this.hideNoticeBarMenuMobile();
      });
    })

    this.backToParentList();
  }

  backToParentList() {
    this.closeChildList.forEach( child => {
      child.addEventListener('click', () => {
        this.closeAllActive();
        this.showNoticeBarMenuMobile();
      })
    })
  }

  hideNoticeBarMenuMobile () {
    if (this.noticeBarMenuMobile) this.noticeBarMenuMobile.dataset.noticeBarMenuMobile = 'hide';
  }

  showNoticeBarMenuMobile () {
    if (this.noticeBarMenuMobile) this.noticeBarMenuMobile.dataset.noticeBarMenuMobile = '';
  }

  closeAllActive () {
    this.closeChildList.forEach( child => {
      child.parentNode.dataset.mobileMenuChildActive = 'false';
    });
  }

  handleAccordion() {
    if (this.breakpoint.matches) {
      this.initialiseAccordion();
    } else {
      this.disableAccordion();
    }
  }

  initialiseAccordion() {
    if (!this.accordionInitilised) {
      new Accordion(this.navAccordion);
      this.accordionInitilised = true;
    }

    this.enableAccordion();
  }

  enableAccordion () {
    this.drawerButton.forEach(button => {
      button.classList.remove(classes.PointerEventNone);
    });
  }

  disableAccordion() {
    this.drawerButton.forEach(button => {
      button.classList.add(classes.PointerEventNone);
    });

    this.drawerContent.forEach(content => {
      content.removeAttribute('style');
      content.setAttribute('date-state', 'open');
    });
  }
}

const createNavMobile = () => new NavigationMobile();
export default createNavMobile;
